import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../public/flexible'
Vue.config.productionTip = false

//引入饿了么组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入视频
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)
require('vue-video-player/src/custom-theme.css')
import Video from 'video.js'
import 'video.js/dist/video-js.css'

import '../public/dropdown.css'




//跳转新页面后回到顶部 
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
