import request from "@/utils/request";
// 修改资料
export const handelUserEdit = (data) => {
    return request({
        url: '/api/user/edit',
        method: 'post',
        data
    })
}
// 个人中心
export const handelUserIndex = (data) => {
    return request({
        url: '/api/user/index',
        method: 'post',
        data
    })
}
// 留言
export const handelUserMessage = (data) => {
    return request({
        url: '/api/user/message',
        method: 'post',
        data
    })
}
// 消息中心-列表
export const handelNoticeList = (data) => {
    return request({
        url: '/api/notice/lists',
        method: 'post',
        data
    })
}
// 消息中心-已读
export const handelNoticeSee = (data) => {
    return request({
        url: '/api/notice/see',
        method: 'post',
        data
    })
}
// 消息中心-删除
export const handelNoticeDelete = (data) => {
    return request({
        url: '/api/notice/delete',
        method: 'post',
        data
    })
}
// 我的订阅
export const handelUserOrderList = (data) => {
    return request({
        url: '/api/user/orderList',
        method: 'post',
        data
    })
}
// 费用记录
export const handelUserOrderLog = (data) => {
    return request({
        url: '/api/user/orderLog',
        method: 'post',
        data
    })
}
//我的证书
export const userProve = (data) => {
    return request({
        url: '/api/user/book',
        method: 'post',
        data
    })
}
//我的奖品
export const userPrize = (data) => {
    return request({
        url: '/api/user/prize',
        method: 'post',
        data
    })
}
//扣款管理
export const manageSubscribe = (data) => {
    return request({
        url: '/api/order/agreementList',
        method: 'post',
        data
    })
}
//取消连续订购服务
export const cancelSubscribe = (data) => {
    return request({
        url: '/api/order/unsign',
        method: 'post',
        data
    })
}
//注销账户
export const signOutAccount = (data) => {
    return request({
        url: '/api/user/signOut',
        method: 'post',
        data
    })
}
