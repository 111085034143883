import request from "@/utils/request";
// 验证码登录
export const handelLogin = (data) => {
    return request({
        url: '/api/login',
        method: 'post',
        data
    })
}
//密码登录
export const handelLoginPass = (data) => {
    return request({
        url: '/api/passwordLogin',
        method: 'post',
        data
    })
}
//注册
export const handelRegister = (data) => {
    return request({
        url: '/api/register',
        method: 'post',
        data
    })
}
//修改密码
export const handelEditPass = (data) => {
    return request({
        url: '/api/changePassword',
        method: 'post',
        data
    })
}
// 获取验证码
export const handelSendMsg = (data) => {
    return request({
        url: '/api/sendMsg',
        method: 'post',
        data
    })
}
// 首页轮播列表
export const handelIndexBanner = (data) => {
    return request({
        url: '/api/index/banner',
        method: 'post',
        data
    })
}
// 我是谁列表
export const handelWhoList = (data) => {
    return request({
        url: '/api/index/whoList',
        method: 'post',
        data
    })
}
// 我是谁详情
export const handelWhoDetail = (data) => {
    return request({
        url: '/api/index/whoDetail',
        method: 'post',
        data
    })
}
// 网站设置
export const handelSetting= (data) => {
    return request({
        url: '/api/setting',
        method: 'post',
        data
    })
}
// 项目介绍
export const handelProject= (data) => {
    return request({
        url: '/api/project/index',
        method: 'post',
        data
    })
}
// 开始练习/讲解视频
export const handelProjectItemList= (data) => {
    return request({
        url: '/api/project/itemList',
        method: 'post',
        data
    })
}
//首页免费体验/本期内容
export const getProject= (data) => {
    return request({
        url: '/api/project/projectList',
        method: 'post',
        data
    })
}
//创建订单
export const createOrders= (data) => {
    return request({
        url: '/api/order/create',
        method: 'post',
        data
    })
}
//获取套餐付费列表
export const getVipPrice= (data) => {
    return request({
        url: '/api/index/vipPrice',
        method: 'post',
        data
    })
}
//获取套餐连续包月价格
export const getAliPrice= (data) => {
    return request({
        url: '/api/index/aliPrice',
        method: 'post',
        data
    })
}
//获取支付宝个人协议页面签约接口
export const getAlipayAgree= (data) => {
    return request({
        url: '/api/order/agreement',
        method: 'post',
        data
    })
}
//创建订单新接口
export const createVipOrders= (data) => {
    return request({
        url: '/api/order/vipCreate',
        method: 'post',
        data
    })
}
//生成支付二维码新街口
export const goVipPay= (data) => {
    return request({
        url: '/api/order/vipPay',
        method: 'post',
        data
    })
}
//生成支付二维码
export const goPay= (data) => {
    return request({
        url: '/api/order/pay',
        method: 'post',
        data
    })
}
//复习测试
export const goReview= (data) => {
    return request({
        url: '/api/project/testList',
        method: 'post',
        data
    })
}
//完成视频任务
export const makeMask= (data) => {
    return request({
        url: '/api/project/videoTask',
        method: 'post',
        data
    })
}
//是否获得涂色纸
export const isGetPaper= (data) => {
    return request({
        url: '/api/user/isPrize',
        method: 'post',
        data
    })
}
//修改涂色纸提示状态
export const editStatus= (data) => {
    return request({
        url: '/api/user/prizeStatus',
        method: 'post',
        data
    })
}
//查询订单支付状态
export const queryOrderStatus= (data) => {
    return request({
        url: '/api/user/orderStatus',
        method: 'post',
        data
    })
}

//记录用户观看音频/视频
export const addViewNums= (data) => {
    return request({
        url: '/api/user/isSee',
        method: 'post',
        data
    })
}

//记录用户观看音频/视频
export const addFreeDownload= (data) => {
    return request({
        url: '/api/user/isDownload',
        method: 'post',
        data
    })
}
