<template>
   <div class="">
    <!-- 登录面板 -->
      <el-dialog :visible.sync="centerDialogVisible" :show-close="false" center width="24%" top="30vh" :close-on-click-modal="true" @close="close">
            <div slot="title" class="dialog-title">
                <span :class="!loginPass?'active':''" @click="loginPass = false">登录账号</span> 
                <!-- <span :class="loginPass?'active':''" @click="loginPass = true">密码登录</span> -->
            </div>
            <div class="dialog">
                <p class="form_row">
                    <input type="text" class="form_mobile" placeholder="请输入手机号" v-model="form.mobile">
                </p>
                <div class="code" v-if="!loginPass">
                    <input type="text" placeholder="请输入验证码" v-model="form.code">
                    <span @click="sendCode">{{ sendmsg }}</span>
                </div>
                <div class="code" v-else>
                    <input type="text" placeholder="请输入密码" v-model="form.password">
                    <span @click="goEdit()">忘记密码</span>
                </div>
                <button class="saveLogin" @click="submitLogin">立即登录</button>
                <div class="login-tip">
                    <!-- <span class="tips1" @click="goRegister()">新用户注册</span> -->
                    <span class="tips2"  @click="noLogin()">跳过，暂不登录</span>
                </div>
                <div class="more">
                    <div class="check">
                        <div class="span" v-if="!checkFlag" @click="checkFlag = !checkFlag"></div>
                        <img v-if="checkFlag" @click="checkFlag = !checkFlag" class="check_img"
                            src="@/assets/user/check_imgurl.png" alt="">
                    </div>
                    <p class="agree">我已阅读并同意<span @click="goAgree(1)">《用户协议》</span><span @click="goAgree(2)">《隐私政策》</span></p>
                </div>
            </div>
      </el-dialog>

       <!-- 注册面板 -->
        <el-dialog :visible.sync="registerVisble" :show-close="false" center width="22%" top="30vh">
            <div slot="title" class="dialog-title">
                <span class="active">注册账号</span> 
                <span style="margin-left: auto;color:#66A51E;" @click="confirmLogin()">已有账号，立即登录</span>
            </div>
            <div class="dialog">
                <p class="form_row">
                    <img src="@/assets/reg1.png" alt="" class="form_icon">
                    <input type="text" class="form_mobile" placeholder="设置用户昵称" v-model="register.nickname">
                </p>
                <!-- <p class="form_row">
                    <img src="@/assets/reg2.png" alt="" class="form_icon">
                    <input type="text" class="form_mobile" placeholder="设置6-18位登录密码" v-model="register.password">
                </p>
                <p class="form_row">
                    <img src="@/assets/reg3.png" alt="" class="form_icon">
                    <input type="text" class="form_mobile" placeholder="再次输入登录密码" v-model="register.password_confirmation">
                </p> -->
                <p class="form_row">
                    <img src="@/assets/reg4.png" alt="" class="form_icon">
                    <input type="text" class="form_mobile" placeholder="输入手机号" v-model="form.mobile">
                </p>
                <div class="code">
                    <img src="@/assets/reg5.png" alt="" class="form_icon">
                    <input type="text" placeholder="请输入验证码" v-model="form.code">
                    <span @click="sendCode">{{ sendmsg }}</span>
                </div>
                <button class="saveLogin" @click="submitLogin">注册并登录</button>
                <div class="more">
                    <div class="check">
                        <div class="span" v-if="!checkFlag" @click="checkFlag = !checkFlag"></div>
                        <img v-if="checkFlag" @click="checkFlag = !checkFlag" class="check_img"
                            src="@/assets/user/check_imgurl.png" alt="">
                    </div>
                    <p class="agree">我已阅读并同意<span @click="goAgree(1)">《用户协议》</span><span @click="goAgree(2)">《隐私政策》</span></p>
                </div>
            </div>
        </el-dialog>

        <!-- 忘记密码面板 -->
        <el-dialog :visible.sync="editVisble" :show-close="false" center width="22%" top="30vh">
            <div slot="title" class="dialog-title">
                <span class="active">忘记密码</span> 
            </div>
            <div class="dialog">
                 <p class="form_row">
                    <img src="@/assets/reg2.png" alt="" class="form_icon">
                    <input type="text" class="form_mobile" placeholder="设置6-18位登录密码" v-model="register.password">
                </p>
                <p class="form_row">
                    <img src="@/assets/reg3.png" alt="" class="form_icon">
                    <input type="text" class="form_mobile" placeholder="再次输入登录密码" v-model="register.password_confirmation">
                </p>
                <p class="form_row">
                    <img src="@/assets/reg4.png" alt="" class="form_icon">
                    <input type="text" class="form_mobile" placeholder="输入手机号" v-model="form.mobile">
                </p>
                <div class="code">
                    <img src="@/assets/reg5.png" alt="" class="form_icon">
                    <input type="text" placeholder="请输入验证码" v-model="form.code">
                    <span @click="sendCode">{{ sendmsg }}</span>
                </div>

                <button class="saveLogin" @click="confirmEdit">确认</button>
                <div class="more">
                    <div class="check">
                        <div class="span" v-if="!checkFlag" @click="checkFlag = !checkFlag"></div>
                        <img v-if="checkFlag" @click="checkFlag = !checkFlag" class="check_img"
                            src="@/assets/user/check_imgurl.png" alt="">
                    </div>
                    <span>我已阅读并同意《用户协议》</span>
                </div>
            </div>
        </el-dialog>
   </div>
</template>
<script>
import { handelSendMsg, handelLogin,handelLoginPass,handelRegister,handelEditPass } from '@/api/home'
import {handelUserIndex} from '@/api/user'
export default {
   name: 'login',
   props: {
    centerDialogVisible: {
      type: Boolean,
      default: false
    }
   },
   watch: {
    centerDialogVisible(newval,oldval) {
        console.log('777',newval)
    }
   },
   data() {
      return {
         form: {
            mobile: '',
            code: '',
            code_key: '',
            password: ''
          },
          register: {
            nickname: '',
            password: '',
            password_confirmation: ''
          },
          sendmsg: '获取验证码',
          isSend: true,//获取验证码
          checkFlag: false,//用户协议
          loginPass: false, //默认密码登录
          registerVisble: false,  //注册面板
          editVisble: false, //修改密码
      }
   },
   created() {
   
   },
   methods: {
      goAgree(type) {
        this.$router.push({path: '/about',query: {type: type}})
      },
      close() {
        this.form.mobile = '';
        this.isSend = true;
        this.sendmsg = "获取验证码";
        clearInterval(this.timeFun);
        this.$emit('close')
      },
      noLogin() {
        this.form.mobile = '';
        this.isSend = true;
        this.sendmsg = "获取验证码";
        clearInterval(this.timeFun);
        this.$emit('nologin')
      },
      confirmLogin() {
        this.$emit('goLogin')
        this.registerVisble = false;
      },
       //去注册
        goRegister() {
            this.$emit('close')
            this.registerVisble = true;
        },
        //去修改
        goEdit() {
            this.$emit('close')
            this.editVisble = true;
        },
        // 发送验证码
        async sendMsgOk() {
            if(!this.isSend) return;
            try {
                const res = await handelSendMsg({ mobile: this.form.mobile })
                if (res.code == 0) {
                    this.form.code_key = res.data.key
                    this.$message.success(res.msg)
                    this.isSend = false;
                    let timer = 60;
                    this.sendmsg = timer + "s后重新发送";
                    this.timeFun = setInterval(() => {
                        --timer;
                        this.sendmsg = timer + "s后重新发送";
                        if (timer == 0) {
                            this.isSend = true;
                            this.sendmsg = "重新发送";
                            clearInterval(this.timeFun);
                        }
                    }, 1000);
                } else {
                    this.isSend = true;
                    this.$message.error(res.msg)
                }
            } catch (error) {
                this.$message.error(error.response.data.msg)
            }
        },
        sendCode() {
            if(!this.form.mobile) {
                this.$message.error('请输入手机号');
                return
            }
            this.sendMsgOk()
        },
        //修改密码
        confirmEdit() {
            if (this.checkFlag) {
               this.nextEdit()
            } else {
                this.$message.error('请先同意《用户协议》')
            }
        },
        async nextEdit() {
             if(!this.register.password) {
                this.$message.error('请输入登录密码');
                return
            }
            if(!this.register.password_confirmation) {
                this.$message.error('请输入确认登录密码');
                return
            }
            if(!this.form.mobile) {
                this.$message.error('请输入手机号');
                return
            }
            if(!this.form.code) {
                this.$message.error('请输入验证码');
                return
            }
            try {
                let data = {
                    mobile: this.form.mobile,
                    code: this.form.code,
                    code_key: this.form.code_key,
                    password: this.register.password,
                    password_confirmation: this.register.password_confirmation
                }
                let res = await handelEditPass(data);

                 if (res.code == 0) {
                    this.$message.success(res.msg)
                    this.editVisble = false;
                    this.centerDialogVisible = true;
                    this.loginPass = true;
                } else {
                    this.$message.error(res.msg)
                }
            }catch(error) {
                this.$message.error(error.response.data.msg)
            }
        },
        //注册
        submitRegister() {
            if (this.checkFlag) {
               this.nextRegister()
            } else {
                this.$message.error('请先同意《用户协议》')
            }
        },
        async nextRegister() {
            if(!this.register.nickname) {
                this.$message.error('请输入昵称');
                return
            }
            // if(!this.register.password) {
            //     this.$message.error('请输入登录密码');
            //     return
            // }
            // if(!this.register.password_confirmation) {
            //     this.$message.error('请输入确认登录密码');
            //     return
            // }
            if(!this.form.mobile) {
                this.$message.error('请输入手机号');
                return
            }
            if(!this.form.code) {
                this.$message.error('请输入验证码');
                return
            }
            try {
                let data = {
                    nickname: this.register.nickname,
                    mobile: this.form.mobile,
                    code: this.form.code,
                    code_key: this.form.code_key
                }
                let res = await handelRegister(data);

                 if (res.code == 0) {
                    this.$message.success(res.msg)
                    this.registerVisble = false;
                    this.centerDialogVisible = true;
                    this.loginPass = true;
                } else {
                    this.$message.error(res.msg)
                }
            }catch(error) {
                this.$message.error(error.response.data.msg)
            }

        },
        // 登录
        async loginOk() {
            //判断是否注册
            if(this.registerVisble) {
                if(!this.register.nickname) {
                    this.$message.error('请输入昵称');
                    return
               }
            }
            if(!this.form.mobile) {
                this.$message.error('请输入手机号');
                return
            }
            if(this.loginPass) { //密码登录
                if(!this.form.password) {
                    this.$message.error('请输入密码');
                    return
                }
            }else {
                if(!this.form.code) {
                    this.$message.error('请输入验证码');
                    return
                }
            }

            try {
                let data = {},res = '';
                if(!this.loginPass) {
                    data = {
                        nickname: this.register.nickname,
                        mobile: this.form.mobile,
                        code: this.form.code,
                        code_key: this.form.code_key
                    }
                    res = await handelLogin(data);
                }else {
                    data = {
                        mobile: this.form.mobile,
                        password: this.form.password
                    }
                    res = await handelLoginPass(data);
                }
                
                if (res.code == 0) {
                    this.$message.success(res.msg)
                    this.$store.commit('setToken', res.data)
                    this.userIndexOk()
                    this.$nextTick()
                    if(this.registerVisble) this.registerVisble = false;
                    else this.$emit('close')
                } else {
                    this.$message.error(res.msg)
                }
            } catch (error) {
                this.$message.error(error.response.data.msg)

            }
        },
         // 个人中心
        async userIndexOk() {
            try {
                const res = await handelUserIndex()
                if (res.code == 0) {
                this.$store.commit('setUser', res.data)
                }
            } catch (error) {
            }
        },
       submitLogin() {
        if (this.checkFlag) {
            this.loginOk()
        } else {
            this.$message.error('请先同意《用户协议》')
        }
    },
   }
}
</script>
<style  lang="scss" scoped>
@media (max-width:1500px) { 
  // 登录注册模态框
        .dialog {
            .form_row {
                display: flex;
                align-items: center;
                padding: 0 .2rem;
                height: 0.48rem;
                background: #F4F4F4;
                border-radius: .1rem;
                margin-bottom: 0.1rem;

                .form_icon {
                    width: 0.18rem;
                    height: 0.18rem;
                    margin-right: 0.1rem;
                }
            }

            .form_mobile {
                width: 100%;
                height: 100%;
                background: none;
                
                border: 0;
                outline: none;
                font-size: .14rem;
            }

            .code {
                width: 100%;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                margin-top: .15rem;
                margin-bottom: .4rem;
                background: #F4F4F4;
                border-radius: .1rem;
                padding: 0 .2rem;

                .form_icon {
                    width: 0.18rem;
                    height: 0.18rem;
                    margin-right: 0.1rem;
                }

                input {
                    width: 1.47rem;
                    height: 0.48rem;
                    background: none;
                    border: 0;
                    outline: none;
                    font-size: .14rem;
                }

                span {
                    display: block;
                    height: .48rem;
                    line-height: .48rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    cursor: pointer;
                    margin-left: auto;
                    font-size: .14rem;
                }
            }

            .more {
                display: flex;
                align-items: center;
                margin-top: .28rem;

                .check {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .span {
                        width: .18rem;
                        height: .18rem;
                        border: .1rem solid #CCCCCC;
                        border-radius: 50%;
                    }

                    .check_img {
                        width: .18rem;
                        height: .18rem;
                        border-radius: 50%;
                    }
                }
                .agree {
                    display: flex;
                    align-items: center;
                    font-size: .14rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.3);
                    margin-left: .1rem;
                }
                span {
                    font-size: .14rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    // color: rgba(0, 0, 0, 0.3);
                    color: #66A51E;
                    cursor: pointer;
                }
            }

            .saveLogin {
                width: 100%;
                height: .48rem;
                background: #66A51E;
                border-radius: .1rem;
                border: 0;
                font-size: .16rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }

            .login-tip {
                display: flex;
                justify-content: space-between;
                font-size: 0.16rem;
                margin: 0.1rem 0 0.2rem;
                .tips1 {
                    color: #66A51E;
                    cursor: pointer;
                }
                .tips2 {
                    color: #B3B3B3;
                    cursor: pointer;
                    margin-left: auto;
                }
            }

            .close {
                width: 70%;
                height: .48rem;
                background: #EFEFEF;
                border-radius: .1rem;
                border: 0;
                font-size: .16rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.7);
                margin-top: .28rem;
            }
        }

        ::v-deep .el-dialog {
            width: 8rem;
            border-radius: .3rem;
            display: flex;
            flex-direction: column;
        }

        ::v-deep .el-dialog__title {
            font-size: .32rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.8);
            line-height: .67rem;
        }

        ::v-deep .el-dialog__header {
            padding: 0.2rem 0.2rem 0.1rem!important;
        }
        ::v-deep .el-dialog__header .dialog-title {
            display: flex;
            width: 100%;
            font-size: .16rem;
            color: rgba(0,0,0,0.3);
            text-align: left;
            cursor: pointer;
        }
        ::v-deep .el-dialog__header .dialog-title span {
            margin-right: 0.2rem;
        }
        ::v-deep .el-dialog__header .dialog-title .active {
            color: #000000;
            font-weight: bold;
        }

        ::v-deep .el-dialog--center .el-dialog__body {
            text-align: initial;
            padding: .1rem .26rem .36rem;
            flex: 1;
        }
}
@media (min-width:1500px) { 
    // 登录注册模态框
        .dialog {
            .form_row {
                display: flex;
                align-items: center;
                height: 48px;
                padding: 0 20px;
                background: #F4F4F4;
                border-radius: 10px;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                .form_icon {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }
            }

            .form_mobile {
                width: 100%;
                height: 100%;
                background: none;
                
                border: 0;
                outline: none;
                
                font-size: 14px;
            }

            .code {
                width: 100%;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                margin-top: 15px;
                margin-bottom: 40px;
                background: #F4F4F4;
                border-radius: 10px;
                padding: 0 20px;

                .form_icon {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }

                input {
                    width: 147px;
                    height: 48px;
                    background: none;
                    border: 0;
                    outline: none;
                    font-size: 14px;
                }

                span {
                    display: block;
                    height: 48px;
                    line-height: 48px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    cursor: pointer;
                    margin-left: auto;
                    font-size: 14px;
                }
            }

            .more {
                display: flex;
                align-items: center;
                margin-top: 28px;

                .check {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .span {
                        width: 18px;
                        height: 18px;
                        border: 1px solid #CCCCCC;
                        border-radius: 50%;
                    }

                    .check_img {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                    }
                }

                .agree {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.3);
                    margin-left: 10px;
                }
                span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.3);
                    color: #66A51E;
                    cursor: pointer;
                }
            }

            .saveLogin {
                width: 100%;
                height: 48px;
                background: #66A51E;
                border-radius: 10px;
                border: 0;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }

            .login-tip {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                margin: 10px 0 20px;
                .tips1 {
                    color: #66A51E;
                    cursor: pointer;
                }
                .tips2 {
                    color: #B3B3B3;
                    cursor: pointer;
                    margin-left: auto;
                }
            }

            .close {
                width: 100%;
                height: 48px;
                background: #EFEFEF;
                border-radius: 10px;
                border: 0;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.7);
                margin-top: 28px;
            }
        }

        ::v-deep .el-dialog {
            width: 800px;
            background: #FFFFFF;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
        }

        ::v-deep .el-dialog__title {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.8);
            line-height: 67px;
        }

        ::v-deep .el-dialog__header {
            padding: 20px 20px 10px!important;
        }
        ::v-deep .el-dialog__header .dialog-title {
            display: flex;
            width: 100%;
            font-size: 16px;
            color: rgba(0,0,0,0.3);
            text-align: left;
            cursor: pointer;
        }
        ::v-deep .el-dialog__header .dialog-title span {
            margin-right: 20px;
        }
        ::v-deep .el-dialog__header .dialog-title .active {
            color: #000000;
            font-weight: bold;
        }

        ::v-deep .el-dialog--center .el-dialog__body {
            text-align: initial;
            padding: 10px 26px 36px;
            flex: 1;
        }

        // 轮播图
        ::v-deep .el-carousel {
            width: 100%;
            height: 100%;
        }

        ::v-deep .el-carousel__container {
            width: 100%;
            height: 100% !important;
        }

        .el-carousel__item img {
            width: 100%;
            height: 100%;
        }

        ::v-deep .el-carousel__indicators .el-carousel__button {
            background: #999;
        }
        
        ::v-deep .el-carousel__indicators .is-active .el-carousel__button{
            background: #66A51E;
        }
}
</style>
