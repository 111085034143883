import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homeView',
    redirect: '/home',
    component: HomeView,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      // 个人中心
      {
        path: '/user',
        name: 'user',
        redirect: '/user/my',
        component: () => import('../views/user/index.vue'),
        children: [
          // 我的消息
          {
            path: '/user/my',
            name: 'user-my',
            redirect: '/user/my/userinfo',
            component: () => import('../components/i-my.vue'),
            children: [
              {
                path: '/user/my/userinfo',
                name: 'user-my-userinfo',
                component: () => import('../components/i-userinfo.vue'),
              },
              {
                path: '/user/my/edit-userinfo',
                name: 'user-my-edit-userinfo',
                component: () => import('../components/i-editUserinfo.vue'),
              }
            ]
          },
          // 我的订阅
          {
            path: '/user/subscribe',
            name: 'user-subscribe',
            component: () => import('../components/i-subscribe.vue'),
          },
          // 我的证书
          {
            path: '/user/prove',
            name: 'user-prove',
            component: () => import('../components/i-prove.vue'),
          },
          // 我的奖品
          {
            path: '/user/prize',
            name: 'user-prize',
            component: () => import('../components/i-prize.vue'),
          },
          // 留言板
          {
            path: '/user/message',
            name: 'user-message',
            component: () => import('../components/i-message.vue'),
          },
          // 费用记录
          {
            path: '/user/record',
            name: 'user-record',
            component: () => import('../components/i-record.vue'),
          },
          // 消息中心
          {
            path: '/user/biotech',
            name: 'user-biotech',
            component: () => import('../components/i-biotech.vue'),
          }
        ]
      },
      // 我是谁
      {
        path: '/who',
        name: 'who',
        // redirect: '/user/my',
        component: () => import('../views/who/index.vue'),
        children: [
          // 百花思维的定义
          {
            path: '/who/define',
            name: 'who-define',
            component: () => import('../views/who/define.vue'),
          },
          // 产品介绍
          {
            path: '/who/introduce',
            name: 'who-introduce',
            component: () => import('../views/who/introduce.vue'),
          },
          // 使用建议
          {
            path: '/who/usage',
            name: 'who-usage',
            component: () => import('../views/who/usage.vue'),
          }
        ]
      },
      // 本期内容
      {
        path: '/current',
        name: 'current',
        // redirect: '/user/my',
        component: () => import('../views/current/index.vue'),
        children: [
          // 练习题目
          {
            path: '/current/practice',
            name: 'current-practice',
            component: () => import('../views/current/practice.vue'),
          },
          // 复习
          {
            path: '/current/review',
            name: 'current-review',
            component: () => import('../views/current/review.vue'),
          },
          // 讲解视频
          {
            path: '/current/explain',
            name: 'current-explain',
            component: () => import('../views/current/explain.vue'),
          }
        ]
      },
      // 课程
      {
        path: '/topic',
        name: 'topic',
        component: () => import('../views/topic/index.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/home/about.vue')
      },{
        path: '/subscribe',
        name: 'subscribe',
        component: () => import('../views/topic/subscribe.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
