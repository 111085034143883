import $axios from 'axios'
import store from '../store/index'
import router from 'vue-router'
import { Message } from 'element-ui';
import { Loading } from 'element-ui';
const instance = $axios.create({
    baseURL: 'https://baihualogic.com',
    timeout: 5000,
});


// loading函数
// 记录请求次数
let needLoadingRequestCount = 0;
let loading;
function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.5)',
    });
}
function endLoading() {
    // 延迟500ms，防止网速特快加载中画面一闪而过
    setTimeout(function () {
        if (loading) loading.close();
    }, 500);
}
// 打开loading
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading();
    }
    needLoadingRequestCount++;
}
// 关闭loading
function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return;
    needLoadingRequestCount--;
    if (needLoadingRequestCount === 0) {
        endLoading();
    }
}

function logOut() {
    //清除缓存用户信息
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('INFO')
    localStorage.removeItem('token')

    this.$router.push('/')
}


instance.interceptors.request.use(function (config) {
    
    // 打开loading
    // showFullScreenLoading();
    const token = store.getters.TOKEN
    if (token) config.headers.Authorization = 'Bearer ' + token
    return config;
}, function (error) {
    // 关闭loading
    // tryHideFullScreenLoading();

    
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    // 关闭loading
    tryHideFullScreenLoading();

    // console.log(response, '888');

    if (response.data.code == 40001) {

        // console.log('999',response.data.code);

        logOut()
        
    } else if (response.data.code == 0) {
        return response.data
    } else {
        return response.data
    }

    return response;
}, function (error) {
    // 关闭loading
    tryHideFullScreenLoading();

    
    if (error.response.status == 401) {

        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
    }
    
    return Promise.reject(error);
});

const request = (options) => {
    options.method = options.method || 'get'
    if (options.method.toLocaleLowerCase() === 'get') {
        options.params = options.data || options.params
        delete options.data
    }
    return instance(options)
}


export default request